

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}


.cards_item {
  display: flex;
  padding: 0.29rem;
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 0.2rem;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
}

.card_title {
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}
.btn{
  text-rendering: auto;
  color: #ffffff;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background: #3d3d3d;
  height: 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: 1px #EF8D9C;
  margin-top: 0.1rem;
  margin-bottom: 1.5rem;
}